import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Typography, Button, Row, Col, Card, Spin, message } from "antd";
import { CopyOutlined, LinkOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { SAMPLE_FORM_SCHEMA } from "./sample";
import "./Simple.css";

const { Title, Paragraph } = Typography;

function SimpleForm() {
  const [data, setData] = useState({ tweet: "", reply: "" });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const textAreaRef = useRef(null); // Ref for textarea

  // Helper function to get query parameters
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
    // example
    // https://sprout.task?webhook=cfe10f37-8e57-497a-be30-23554ed85786&campaign=001
  };

  const query = useQuery();
  const webhook = query.get("webhook");
  const campaign = query.get("campaign");

  useEffect(() => {
    if (webhook && campaign) {
      // Construct URL using webhook and campaign parameters
      const url = `https://app.legions.bot/webhook/${webhook}?campaign=${campaign}`;

      axios
        .get(url)
        .then((response) => {
          setData(response.data);
          setLoading(false); // Stop loading once the response is received
        })
        .catch((err) => {
          if (err.response) {
            setError(`Error: ${err.response.statusText}`);
          } else if (err.request) {
            setError("No response received from the server.");
          } else {
            setError("Error: " + err.message);
          }
          setLoading(false);
        });
    } else {
      setError("Missing webhook or campaign parameters in the URL.");
      setLoading(false);
    }
  }, [webhook, campaign]);

  const handleCopy = () => {
    if (textAreaRef.current) {
      textAreaRef.current.select();
      document.execCommand("copy"); // Copy text
      message.success("Comment copied to clipboard!");
    }
  };

  return (
    <div className="App">
      <header className="SimpleForm-header">
        <Card style={{ maxWidth: 400, margin: "0 auto", padding: "20px" }}>
          <Title level={2} style={{ marginBottom: "16px" }}>
            Instructions
          </Title>
          {loading ? (
            <Spin tip="Loading... Please wait." />
          ) : error ? (
            <Paragraph type="danger">{error}</Paragraph>
          ) : (
            <div className="instructions">
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Paragraph className="step">
                    <strong>Step 1:</strong> Copy this comment:
                  </Paragraph>
                  <textarea
                    ref={textAreaRef}
                    className="comment-box"
                    value={data.reply}
                    readOnly
                    rows={4} // Adjust the number of rows based on content length
                  />
                  <Button
                    icon={<CopyOutlined />}
                    onClick={handleCopy}
                    style={{ marginTop: "10px", width: "100%" }}
                  >
                    Copy to Clipboard
                  </Button>
                </Col>
                <div style={{ margin: "auto", padding: "20px" }}>
                  -----------------------
                </div>
                <Col span={24}>
                  <Paragraph className="step">
                    <strong>Step 2:</strong> Paste as a reply tweet below:
                  </Paragraph>
                  <Paragraph className="tweet-url">
                    <a
                      href={data.tweet}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ wordBreak: "break-all" }}
                    >
                      {data.tweet}
                    </a>
                  </Paragraph>
                  <Button
                    type="primary"
                    href={data.tweet}
                    target="_blank"
                    rel="noopener noreferrer"
                    icon={<LinkOutlined />}
                    style={{ width: "100%" }}
                  >
                    Reply to Tweet
                  </Button>
                </Col>
              </Row>
            </div>
          )}
        </Card>
      </header>
    </div>
  );
}

export default SimpleForm;
