export const SAMPLE_FORM_SCHEMA = {
  title: "User Registration",
  description: "Please fill out the form below to create a new account.",
  steps: [
    {
      label: "Personal Information",
      description: "Enter your personal details.",
      cells: [
        {
          key: "first_name",
          label: "First Name",
          type: "input",
          value: "text",
          required: true,
        },
        {
          key: "last_name",
          label: "Last Name",
          type: "input",
          value: "text",
          required: true,
        },
        {
          key: "age",
          label: "Age",
          type: "input",
          value: "number",
          required: false,
        },
        {
          key: "profile_url",
          label: "Profile URL",
          type: "input",
          value: "url",
          required: false,
        },
        {
          key: "instructions",
          label: "Instructions",
          type: "copy",
          value:
            "Please ensure all personal information is accurate before proceeding.",
        },
        {
          key: "open_link",
          label: "Learn More",
          type: "button",
          value: "https://example.com/learn-more",
        },
      ],
    },
    {
      label: "Account Preferences",
      description: "Choose your account settings.",
      cells: [
        {
          key: "account_type",
          label: "Account Type",
          type: "single-input",
          value: "Basic,Standard,Premium",
          required: true,
        },
        {
          key: "preference_notes",
          label: "Note",
          type: "copy",
          value: "Select the account type that best suits your needs.",
        },
        {
          key: "open_link",
          label: "Compare Plans",
          type: "button",
          value: "https://example.com/compare-plans",
        },
      ],
    },
    {
      label: "Interests",
      description: "Select your areas of interest.",
      cells: [
        {
          key: "interests",
          label: "Interests",
          type: "multi-input",
          value: "Technology,Health,Finance,Education",
          required: false,
        },
        {
          key: "information",
          label: "Information",
          type: "copy",
          value:
            "You can select multiple interests that align with your preferences.",
        },
        {
          key: "open_link",
          label: "Explore Interests",
          type: "button",
          value: "https://example.com/explore-interests",
        },
      ],
    },
    {
      label: "Appointment Date",
      description: "Choose a preferred date for your first meeting.",
      cells: [
        {
          key: "appointment_date",
          label: "Date",
          type: "date",
          value: null,
          required: true,
        },
        {
          key: "open_link",
          label: "View Calendar",
          type: "button",
          value: "https://example.com/calendar",
        },
      ],
    },
  ],
  submit_final: {
    url: "https://api.example.com/submit-form",
    auth: "Bearer your-auth-token",
    redirect: "https://example.com/thank-you",
  },
};
