import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Typography,
  Button,
  Row,
  Col,
  Spin,
  message,
  Checkbox,
  Input,
  Select,
  DatePicker,
  InputNumber,
} from "antd";
import { CopyOutlined, ExportOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { SAMPLE_FORM_SCHEMA } from "./sample";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import "./GigWorker.css";

const { Title, Paragraph } = Typography;
const { TextArea } = Input;
const { Option } = Select;

function GigWorker() {
  const [formSchema, setFormSchema] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [completedSteps, setCompletedSteps] = useState({});
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [redirectUrl, setRedirectUrl] = useState("");

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const key = query.get("key");
  const campaign = query.get("campaign");

  useEffect(() => {
    if (key && campaign) {
      const url = `https://api.legions.bot/api/w/run-sybil/jobs/run_wait_result/f/f/runsybil/gig_worker_task_backend`; // PROD
      // const url = "https://api.legions.bot/api/w/run-sybil/capture_u/f/runsybil/gig_worker_task_backend"; // DEV
      const body = {
        route: "GET/get_task",
        payload: {
          campaign,
          client_key_gigworker: key,
        },
      };
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer KWjVk9D1xYBLqVoLAYkG3CZjiKrkzwEz",
      };
      axios
        .post(url, body, { headers })
        .then((response) => {
          console.log("response", response);
          setFormSchema(response.data);
          setLoading(false);
        })
        .catch((err) => {
          if (err.response) {
            setError(`Error: ${err.response.statusText}`);
          } else if (err.request) {
            setError("No response received from the server.");
          } else {
            setError("Error: " + err.message);
          }
          setLoading(false);
        });
    } else {
      setFormSchema(SAMPLE_FORM_SCHEMA);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (formSchema && formSchema.steps) {
      const initialData = {};
      formSchema.steps.forEach((step) => {
        step.cells.forEach((cell) => {
          if (cell.type !== "button" && cell.type !== "copy") {
            initialData[cell.key] = cell.type === "multi-input" ? [] : "";
          }
        });
      });
      setFormData(initialData);
    }
  }, [formSchema]);

  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        message.success("Text copied to clipboard!");
      })
      .catch((err) => {
        message.error("Failed to copy text.");
        console.error("Copy failed: ", err);
      });
  };

  const handleInputChange = (key, value) => {
    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const validateStep = (stepIndex) => {
    const step = formSchema.steps[stepIndex];
    const stepErrors = {};

    step.cells.forEach((cell) => {
      if (cell.required && !formData[cell.key]) {
        stepErrors[cell.key] = `${cell.label} is required`;
      }
    });

    setFormErrors((prev) => ({
      ...prev,
      [stepIndex]: stepErrors,
    }));

    return Object.keys(stepErrors).length === 0;
  };

  const handleStepCompletion = (stepIndex, checked) => {
    if (checked && !validateStep(stepIndex)) {
      message.error(
        "Please fill in all required fields before marking as complete."
      );
      return;
    }

    setCompletedSteps((prev) => ({
      ...prev,
      [stepIndex]: checked,
    }));
  };

  const allStepsCompleted = formSchema
    ? formSchema.steps &&
      formSchema.steps.every((_, index) => completedSteps[index])
    : false;

  const handleSubmit = () => {
    if (formSchema && formSchema.submit_final) {
      setIsSubmitting(true);
      const { redirect } = formSchema.submit_final;

      const proofs = Object.keys(formData).map((key) => {
        const p = { uuid: key, proof_url: formData[key] };
        return p;
      });

      // const url = `https://api.legions.bot/api/w/run-sybil/capture_u/f/runsybil/gig_worker_task_backend`; // DEV
      const url = `https://api.legions.bot/api/w/run-sybil/jobs/run_wait_result/f/f/runsybil/gig_worker_task_backend`; // PROD
      const body = {
        route: "POST/complete_task",
        payload: {
          records: proofs, // { "campaign_record_uuid": "proof_url" }
          campaign,
          client_key_gigworker: key,
        },
      };
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer KWjVk9D1xYBLqVoLAYkG3CZjiKrkzwEz",
      };
      axios
        .post(url, body, { headers })
        .then((response) => {
          message.success("Form submitted successfully!");
          const responseId = response.data.response_id || uuidv4();
          setSuccessMessage(
            `Successfully submitted the form. Your response#${responseId}`
          );
          setIsSubmitting(false);
        })
        .catch((error) => {
          message.error("Failed to submit the form.");
          console.error("Submission error:", error);
          setIsSubmitting(false);
        });
    }
  };

  const renderCell = (cell, stepIndex) => {
    const commonProps = {
      id: cell.key,
      name: cell.key,
      value: formData[cell.key] || "",
      onChange: (e) => handleInputChange(cell.key, e.target.value),
      className: `input-element ${cell.required ? "required" : ""}`,
      status: formErrors[stepIndex]?.[cell.key] ? "error" : "",
    };

    switch (cell.type) {
      case "input":
        return (
          <div key={cell.key} className="cell">
            <label htmlFor={cell.key} className="cell-label">
              {cell.label}{" "}
              {cell.required && <span className="required-mark">*</span>}
            </label>
            {cell.value === "number" ? (
              <InputNumber {...commonProps} min={0} style={{ width: "100%" }} />
            ) : (
              <Input {...commonProps} type={cell.value} />
            )}
            {formErrors[stepIndex]?.[cell.key] && (
              <div className="error-message">
                {formErrors[stepIndex][cell.key]}
              </div>
            )}
          </div>
        );
      case "single-input":
        return (
          <div key={cell.key} className="cell">
            <label htmlFor={cell.key} className="cell-label">
              {cell.label}{" "}
              {cell.required && <span className="required-mark">*</span>}
            </label>
            <Select
              {...commonProps}
              placeholder={`Select ${cell.label}`}
              onChange={(value) => handleInputChange(cell.key, value)}
            >
              {cell.value.split(",").map((option) => (
                <Option key={option.trim()} value={option.trim()}>
                  {option.trim()}
                </Option>
              ))}
            </Select>
            {formErrors[stepIndex]?.[cell.key] && (
              <div className="error-message">
                {formErrors[stepIndex][cell.key]}
              </div>
            )}
          </div>
        );
      case "multi-input":
        return (
          <div key={cell.key} className="cell">
            <label htmlFor={cell.key} className="cell-label">
              {cell.label}{" "}
              {cell.required && <span className="required-mark">*</span>}
            </label>
            <Select
              {...commonProps}
              mode="multiple"
              placeholder={`Select ${cell.label}`}
              onChange={(value) => handleInputChange(cell.key, value)}
            >
              {cell.value.split(",").map((option) => (
                <Option key={option.trim()} value={option.trim()}>
                  {option.trim()}
                </Option>
              ))}
            </Select>
            {formErrors[stepIndex]?.[cell.key] && (
              <div className="error-message">
                {formErrors[stepIndex][cell.key]}
              </div>
            )}
          </div>
        );
      case "date":
        return (
          <div key={cell.key} className="cell">
            <label htmlFor={cell.key} className="cell-label">
              {cell.label}{" "}
              {cell.required && <span className="required-mark">*</span>}
            </label>
            <DatePicker
              {...commonProps}
              style={{ width: "100%" }}
              value={formData[cell.key] ? moment(formData[cell.key]) : null}
              onChange={(date, dateString) =>
                handleInputChange(cell.key, dateString)
              }
            />
            {formErrors[stepIndex]?.[cell.key] && (
              <div className="error-message">
                {formErrors[stepIndex][cell.key]}
              </div>
            )}
          </div>
        );
      case "copy":
        return (
          <div key={cell.key} className="cell">
            <label className="cell-label">{cell.label}</label>
            <TextArea
              value={cell.value}
              readOnly
              rows={4}
              className="copy-textarea"
            />
            <Button
              icon={<CopyOutlined />}
              onClick={() => handleCopy(cell.value)}
              style={{ marginTop: "8px" }}
              block
            >
              Copy to Clipboard
            </Button>
          </div>
        );
      case "button":
        return (
          <div key={cell.key} className="cell">
            <Button
              type="primary"
              href={cell.value}
              target="_blank"
              rel="noopener noreferrer"
              icon={<ExportOutlined />}
              block
              className="action-button"
            >
              {cell.label}
            </Button>
          </div>
        );
      default:
        return null;
    }
  };

  if (successMessage) {
    return (
      <div className="App">
        <Title level={2}>{successMessage}</Title>
        {redirectUrl && (
          <a href={redirectUrl}>
            <Button type="primary">
              {formSchema.submit_final.redirectLabel}
            </Button>
          </a>
        )}
      </div>
    );
  }

  if (!formSchema || formSchema.steps.length === 0) {
    return null;
  }

  return (
    <div className="App">
      <Title level={2} className="form-title">
        {formSchema ? formSchema.title : "Loading..."}
      </Title>
      <Paragraph className="form-description">
        {formSchema ? formSchema.description : ""}
      </Paragraph>
      <br />
      <br />
      {loading ? (
        <Spin tip="Loading... Please wait." />
      ) : error ? (
        <Paragraph type="danger">{error}</Paragraph>
      ) : (
        <>
          <Row gutter={[16, 16]} justify="center">
            {formSchema.steps &&
            Array.isArray(formSchema.steps) &&
            formSchema.steps.length > 0 ? (
              formSchema.steps.map((step, stepIndex) => (
                <Col xs={24} sm={24} md={24} lg={24} key={stepIndex}>
                  <div
                    className={`step-card ${
                      completedSteps[stepIndex] ? "completed" : ""
                    }`}
                  >
                    <div className="step-title">
                      <Title level={4}>{step.label}</Title>
                    </div>

                    <div>
                      <Paragraph className="step-description">
                        {step.description}
                      </Paragraph>
                      {step.cells &&
                        Array.isArray(step.cells) &&
                        step.cells.map((cell) => renderCell(cell, stepIndex))}
                    </div>
                    <div className="completion-checkbox">
                      <Checkbox
                        checked={!!completedSteps[stepIndex]}
                        onChange={(e) =>
                          handleStepCompletion(stepIndex, e.target.checked)
                        }
                      >
                        Mark as complete
                      </Checkbox>
                    </div>
                  </div>
                </Col>
              ))
            ) : (
              <Paragraph>Ok, Finished</Paragraph>
            )}
          </Row>

          {formSchema.steps?.length > 0 && (
            <div
              style={{
                textAlign: "center",
                marginTop: "24px",
                width: "100%",
                maxWidth: "1000px",
              }}
            >
              <Paragraph>Mark every step complete before submitting</Paragraph>
              <Button
                type="primary"
                onClick={handleSubmit}
                disabled={!allStepsCompleted}
                size="large"
                block
                loading={isSubmitting}
              >
                Submit
              </Button>
            </div>
          )}
          <br />
          <br />
          <br />
          <br />
        </>
      )}
    </div>
  );
}

export default GigWorker;
