const FinishRedirect = () => {
  return (
    <div
      style={{
        // make it center align on page
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "50vh",
      }}
    >
      <h1>Thank you!</h1>
    </div>
  );
};

export default FinishRedirect;
