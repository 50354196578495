import React, { useState, useEffect } from "react";
import {
  Input,
  Button,
  List,
  Row,
  Col,
  message,
  Popover,
  Divider,
  Typography,
  Switch,
} from "antd";
import {
  TwitterOutlined,
  KeyOutlined,
  ClearOutlined,
  DownOutlined,
  SaveOutlined,
  SettingOutlined,
  UserSwitchOutlined,
  MessageOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import moment from "moment";

const { Title } = Typography;

function formatNumberWithSuffix(numberString) {
  const num = parseFloat(numberString);

  if (num >= 1_000_000) {
    return (num / 1_000_000).toFixed(1).replace(/\.0$/, "") + "M";
  } else if (num >= 1_000) {
    return (num / 1_000).toFixed(1).replace(/\.0$/, "") + "k";
  } else {
    return num.toString();
  }
}

const Spearfisher = () => {
  const [openaiKey, setOpenaiKey] = useState(
    () => localStorage.getItem("openaiKey") || ""
  );
  const [rapidapiKey, setRapidapiKey] = useState(
    () => localStorage.getItem("rapidapiKey") || ""
  );
  const [pineconeKey, setPineconeKey] = useState(
    () => localStorage.getItem("pineconeKey") || ""
  );
  const [backendWebhookUrl, setBackendWebhookUrl] = useState(
    () => localStorage.getItem("backendWebhookUrl") || ""
  );
  const [pineconePineconeUsername, setPineconePineconeUsername] = useState(
    () => localStorage.getItem("pineconePineconeUsername") || ""
  );
  const [tagsCsv, setTagsCsv] = useState(
    () => localStorage.getItem("tagsCsv") || ""
  );
  const [campaign, setCampaign] = useState(
    () => localStorage.getItem("campaign") || ""
  );
  const [prompt, setPrompt] = useState(
    () => localStorage.getItem("prompt") || ""
  );
  const [listId, setListId] = useState(
    () => localStorage.getItem("listId") || ""
  );
  const [feedSearchTerm, setFeedSearchTerm] = useState(
    () => localStorage.getItem("feedSearchTerm") || ""
  );
  const [useQuoteTweet, setUseQuoteTweet] = useState(() => {
    const storedValue = localStorage.getItem("useQuoteTweet");
    return storedValue === "true";
  });

  const [useTweetDirectly, setUseTweetDirectly] = useState(() => {
    const storedValue = localStorage.getItem("useTweetDirectly");
    return storedValue === "true";
  });

  const [proxy, setProxy] = useState(() => localStorage.getItem("proxy") || "");
  const [twitterAuth, setTwitterAuth] = useState(
    () => localStorage.getItem("twitterAuth") || ""
  );

  const [pineconeUrl, setPineconeUrl] = useState(
    () => localStorage.getItem("pineconeUrl") || ""
  );
  const [pineconeNamespace, setPineconeNamespace] = useState(
    () => localStorage.getItem("pineconeNamespace") || ""
  );
  const [tweets, setTweets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [responses, setResponses] = useState({});
  const [generatingResponses, setGeneratingResponses] = useState({});
  const [postingTweet, setPostingTweet] = useState({});
  const [quoteTweetMetadatas, setQuoteTweetMetadatas] = useState({});
  const [savingStates, setSavingStates] = useState({});
  const [saveAllLoading, setSaveAllLoading] = useState(false);
  const [sybilTweetContent, setSybilTweetContent] = useState("");
  const [postSybilNowLoading, setPostSybilNowLoading] = useState(false);
  const [postedTweetUrls, setPostedTweetUrls] = useState({});
  const [matchesState, setMatchesState] = useState({});
  const [savedToCampaign, setSavedToCampaign] = useState({});
  const [isFeedMode, setIsFeedMode] = useState(false);
  const [feedBottomCursor, setFeedBottomCursor] = useState("");
  const [listBottomCursor, setListBottomCursor] = useState("");

  const [tagRandomMentionFlag, setTagRandomMentionFlag] = useState(() => {
    const storedValue = localStorage.getItem("tagRandomMentionFlag");
    return storedValue === "true";
  });

  const [backendWebhookUserMentionsURL, setBackendWebhookUserMentionsURL] =
    useState(() => localStorage.getItem("backendWebhookUserMentionsURL") || "");

  useEffect(() => {
    localStorage.setItem("openaiKey", openaiKey);
    localStorage.setItem("rapidapiKey", rapidapiKey);
    localStorage.setItem("pineconeKey", pineconeKey);
    localStorage.setItem("backendWebhookUrl", backendWebhookUrl);
    localStorage.setItem("pineconePineconeUsername", pineconePineconeUsername);
    localStorage.setItem("tagsCsv", tagsCsv);
    localStorage.setItem("campaign", campaign);
    localStorage.setItem("prompt", prompt);
    localStorage.setItem("listId", listId);
    localStorage.setItem("feedSearchTerm", feedSearchTerm);
    localStorage.setItem("proxy", proxy);
    localStorage.setItem("twitterAuth", twitterAuth);
    localStorage.setItem("useQuoteTweet", useQuoteTweet.toString());
    localStorage.setItem("useTweetDirectly", useTweetDirectly.toString());
    localStorage.setItem(
      "tagRandomMentionFlag",
      tagRandomMentionFlag.toString()
    );
    localStorage.setItem(
      "backendWebhookUserMentionsURL",
      backendWebhookUserMentionsURL
    );
    localStorage.setItem("pineconeUrl", pineconeUrl);
    localStorage.setItem("pineconeNamespace", pineconeNamespace);
  }, [
    openaiKey,
    rapidapiKey,
    pineconeKey,
    backendWebhookUrl,
    pineconePineconeUsername,
    tagsCsv,
    campaign,
    prompt,
    listId,
    feedSearchTerm,
    useQuoteTweet,
    proxy,
    twitterAuth,
    useTweetDirectly,
    tagRandomMentionFlag,
    backendWebhookUserMentionsURL,
    pineconeUrl,
    pineconeNamespace,
  ]);

  const extractListId = (input) => {
    const match = input.match(/lists\/(\d+)/);
    return match ? match[1] : input;
  };

  const clearTweetRelatedState = () => {
    setTweets([]);
    setResponses({});
    setGeneratingResponses({});
    setPostingTweet({});
    setQuoteTweetMetadatas({});
    setSavingStates({});
    setPostedTweetUrls({});
    setMatchesState({});
    setSavedToCampaign({});
  };

  const clearResponse = (tweetId) => {
    setResponses((prev) => {
      const newResponses = { ...prev };
      delete newResponses[tweetId];
      return newResponses;
    });
    setQuoteTweetMetadatas((prev) => {
      const newMetadatas = { ...prev };
      delete newMetadatas[tweetId];
      return newMetadatas;
    });
    setMatchesState((prev) => {
      const newMatches = { ...prev };
      delete newMatches[tweetId];
      return newMatches;
    });
    setSavedToCampaign((prev) => {
      const newSaved = { ...prev };
      delete newSaved[tweetId];
      return newSaved;
    });
  };

  const fetchInitialFeedTweets = async () => {
    setLoading(true);

    const queryString = new URLSearchParams({
      q: feedSearchTerm,
      count: "100",
      type: "Latest",
      safe_search: "true",
    }).toString();
    const url = `https://twitter135.p.rapidapi.com/Search/?${queryString}`;
    const options = {
      method: "GET",
      headers: {
        "x-rapidapi-key": rapidapiKey,
        "x-rapidapi-host": "twitter135.p.rapidapi.com",
      },
    };

    try {
      const result = await fetchAndProcessTweets(url, options);
      clearTweetRelatedState();
      setTweets(result.newTweets);
      setFeedBottomCursor(result.newBottomCursor);
      setLoading(false);
    } catch (error) {
      message.error("Failed to fetch feed tweets");
      console.error(error);
      setLoading(false);
    }
  };

  const fetchInitialListTweets = async () => {
    setLoading(true);
    const extractedListId = extractListId(listId);
    const url = `https://twitter135.p.rapidapi.com/v2/ListTimeline/?list_id=${extractedListId}&count=100`;
    const options = {
      method: "GET",
      headers: {
        "x-rapidapi-key": rapidapiKey,
        "x-rapidapi-host": "twitter135.p.rapidapi.com",
      },
      params: {
        q: feedSearchTerm,
        count: "100",
        type: "Latest",
        safe_search: "true",
      },
    };

    try {
      const result = await fetchAndProcessTweets(url, options);
      clearTweetRelatedState();
      setTweets(result.newTweets);
      setListBottomCursor(result.newBottomCursor);
      setLoading(false);
    } catch (error) {
      message.error("Failed to fetch list tweets");
      console.error(error);
      setLoading(false);
    }
  };

  const fetchMoreFeedTweets = async () => {
    setLoading(true);
    const queryString = new URLSearchParams({
      q: feedSearchTerm,
      count: "100",
      type: "Latest",
      safe_search: "true",
      cursor: feedBottomCursor,
    }).toString();
    const url = `https://twitter135.p.rapidapi.com/Search/?${queryString}`;
    const options = {
      method: "GET",
      headers: {
        "x-rapidapi-key": rapidapiKey,
        "x-rapidapi-host": "twitter135.p.rapidapi.com",
      },
    };

    try {
      const result = await fetchAndProcessTweets(url, options);
      clearTweetRelatedState();
      setTweets((prevTweets) => [...prevTweets, ...result.newTweets]);
      setFeedBottomCursor(result.newBottomCursor);
    } catch (error) {
      message.error("Failed to fetch more feed tweets");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchMoreListTweets = async () => {
    setLoading(true);
    const extractedListId = extractListId(listId);
    const url = `https://twitter135.p.rapidapi.com/v2/ListTimeline/?list_id=${extractedListId}&count=20&cursor=${listBottomCursor}`;
    const options = {
      method: "GET",
      headers: {
        "x-rapidapi-key": rapidapiKey,
        "x-rapidapi-host": "twitter135.p.rapidapi.com",
      },
    };

    try {
      const result = await fetchAndProcessTweets(url, options);
      clearTweetRelatedState();
      setTweets((prevTweets) => [...prevTweets, ...result.newTweets]);
      setListBottomCursor(result.newBottomCursor);
    } catch (error) {
      message.error("Failed to fetch more list tweets");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAndProcessTweets = async (url, options) => {
    console.log(options);
    const response = await fetch(url, options);
    const result = await response.json();
    console.log(">>>> ", result);

    let newTweets = [];
    let newBottomCursor = "";

    if (isFeedMode) {
      console.log(result);
      const instructions =
        result.data.search_by_raw_query.search_timeline.timeline.instructions;
      instructions.forEach((instruction) => {
        if (instruction.type === "TimelineAddEntries") {
          newTweets = instruction.entries.filter(
            (entry) => entry.content.itemContent
          );
        } else if (
          instruction.type === "TimelineReplaceEntry" &&
          instruction.entry.content.entryType === "TimelineTimelineCursor" &&
          instruction.entry.content.cursorType === "Bottom"
        ) {
          newBottomCursor = instruction.entry.content.value;
        }
      });
    } else {
      newTweets =
        result.data.list.tweets_timeline.timeline.instructions[0].entries;
      newBottomCursor = newTweets.slice(-1)[0].content.value;
    }

    return { newTweets, newBottomCursor };
  };

  const generateResponse = async (tweetId, tweetContent) => {
    setGeneratingResponses((prev) => ({ ...prev, [tweetId]: true }));

    try {
      // Step 1: Generate OpenAI embeddings
      const embeddingResponse = await fetch(
        "https://api.openai.com/v1/embeddings",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${openaiKey}`,
          },
          body: JSON.stringify({
            model: "text-embedding-3-small",
            input: `What is the best appropriate reply to this tweet?\nOriginal Tweet: ${tweetContent}`,
          }),
        }
      );

      const embeddingResult = await embeddingResponse.json();
      const embeddings = embeddingResult.data[0].embedding;

      let quotedTweet = null;

      if (useQuoteTweet) {
        // Step 2: Query Pinecone
        const pineconeResponse = await fetch(`${pineconeUrl}/query`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Api-Key": pineconeKey,
          },
          body: JSON.stringify({
            namespace: pineconeNamespace,
            vector: embeddings,
            topK: 2,
            includeValues: true,
            includeMetadata: true,
            filter: {
              $and: [
                { username: pineconePineconeUsername },
                { tags: { $in: tagsCsv.split(",") } },
              ],
            },
          }),
        });
        console.log(pineconeResponse);
        const pineconeResult = await pineconeResponse.json();
        console.log(pineconeResult);
        if (pineconeResult.matches.length === 0) {
          message.error("No matches found in Pinecone");
          return;
        }
        console.log(pineconeResult.matches);
        setMatchesState((prev) => ({
          ...prev,
          [tweetId]: {
            matches: pineconeResult.matches.map((m) => m.metadata),
            index: 0,
          },
        }));
        quotedTweet = pineconeResult.matches[0].metadata;
      }

      // Step 3: Generate response using OpenAI
      const openaiResponse = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${openaiKey}`,
          },
          body: JSON.stringify({
            model: "gpt-4o-mini",
            response_format: { type: "json_object" },
            messages: [
              {
                role: "user",
                content: `Write me a short tweet reply to the original_tweet_text${
                  useQuoteTweet
                    ? ", to somehow link it to quoted_tweet_text so that we can siphon user traffic to our quoted tweet"
                    : ""
                }. Keep the tweet reply short and sound casual not like a shill, unless otherwise specified by the user prompt.
  
                Here is the user prompt:
                ${prompt}
                
                Here is the original_tweet_text: 
                ${tweetContent}
                
                ${
                  useQuoteTweet
                    ? `Here is the quoted_tweet_text:
                ${quotedTweet.text}`
                    : ""
                }
                
                Please respond with valid JSON in this format:
                { "comment": "generated comment" }
                no frills, just the json`,
              },
            ],
          }),
        }
      );

      const openaiResult = await openaiResponse.json();
      const generatedComment = JSON.parse(
        openaiResult.choices[0].message.content
      ).comment;

      setResponses((prev) => ({
        ...prev,
        [tweetId]: generatedComment,
      }));

      if (useQuoteTweet && quotedTweet) {
        setQuoteTweetMetadatas((prev) => ({
          ...prev,
          [tweetId]: quotedTweet,
        }));
      }
      message.success("Generated reply");
    } catch (error) {
      message.error("Failed to generate response");
      console.error(error);
    } finally {
      setGeneratingResponses((prev) => ({ ...prev, [tweetId]: false }));
    }
  };

  const iterateResponseOptions = (tweetId, direction) => {
    setMatchesState((prev) => {
      const currentState = prev[tweetId];
      if (!currentState || currentState.matches.length === 0) return prev;

      let newIndex = currentState.index + direction;
      if (newIndex < 0) newIndex = currentState.matches.length - 1;
      if (newIndex >= currentState.matches.length) newIndex = 0;

      const newQuotedTweetMetadata = currentState.matches[newIndex];

      setQuoteTweetMetadatas((prevMetadata) => ({
        ...prevMetadata,
        [tweetId]: newQuotedTweetMetadata,
      }));

      return {
        ...prev,
        [tweetId]: { ...currentState, index: newIndex },
      };
    });
  };

  const generateAllResponses = () => {
    tweets.forEach((item, index) => {
      if (item.content.entryType === "TimelineTimelineItem") {
        const tweet = item.content.itemContent.tweet_results.result;
        const tweetId = tweet.rest_id;
        setTimeout(() => {
          if (tweet.legacy && tweet.legacy.full_text) {
            generateResponse(tweetId, tweet.legacy.full_text);
          }
        }, index * 2000);
      }
    });
    message.success("Generated responses for all tweets");
  };

  const saveToCampaign = async (tweetId) => {
    setSavingStates((prev) => ({ ...prev, [tweetId]: true }));

    const tweet = tweets.find(
      (item) =>
        item.content.entryType === "TimelineTimelineItem" &&
        item.content.itemContent.tweet_results.result.rest_id === tweetId
    ).content.itemContent.tweet_results.result;

    let fullReplyTweetText = `${responses[tweetId]}`;

    if (tagRandomMentionFlag && backendWebhookUserMentionsURL) {
      try {
        const mentionResponse = await fetch(backendWebhookUserMentionsURL);
        const mentionData = await mentionResponse.json();
        const randomUsername = mentionData.username;

        fullReplyTweetText += `\n\n\n  @${randomUsername.trim()} `;
      } catch (error) {
        console.error("Error fetching random mention:", error);
        message.error("Failed to fetch random mention");
      }
    }

    if (quoteTweetMetadatas[tweetId]) {
      fullReplyTweetText += `\n${quoteTweetMetadatas[tweetId].url}`;
    }

    const payload = {
      original_tweet_url: `https://x.com/user/status/${tweetId}`,
      original_tweet_username:
        tweet.core.user_results.result.legacy.screen_name,
      original_tweet_text: tweet.legacy.full_text,
      reply_tweet_text: fullReplyTweetText || "",
      quoted_tweet_url: quoteTweetMetadatas[tweetId]?.url || "",
      quoted_tweet_text: quoteTweetMetadatas[tweetId]?.text || "",
      quoted_tweet_username: quoteTweetMetadatas[tweetId]?.username || "",
      campaign: campaign,
      original_tweet_id: tweetId,
      assigned: false,
    };

    try {
      const response = await fetch(backendWebhookUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Failed to save to campaign");
      }
      const result = await response.json();
      console.log("response", result);
      if (result.status === 200) {
        message.success("Saved to campaign successfully");
        console.log(`setSavedToCampaign(${tweetId})`);
        setSavedToCampaign((prev) => ({ ...prev, [tweetId]: true }));
      } else if (result.status === 403) {
        message.error("Already exists for this campaign");
      }
    } catch (error) {
      console.error("Error saving to campaign:", error);
      message.error("Failed to save to campaign");
    } finally {
      setSavingStates((prev) => ({ ...prev, [tweetId]: false }));
    }
  };

  const saveAllToCampaign = async () => {
    setSaveAllLoading(true);
    const tweetsToSave = tweets.filter(
      (item) =>
        item.content.entryType === "TimelineTimelineItem" &&
        responses[item.content.itemContent.tweet_results.result.rest_id]
    );

    for (const item of tweetsToSave) {
      const tweet = item.content.itemContent.tweet_results.result;
      const tweetId = tweet.rest_id;
      await saveToCampaign(tweetId);
      await new Promise((resolve) => setTimeout(resolve, 3000)); // 1 second delay
    }

    setSaveAllLoading(false);
    message.success("All responses saved to campaign");
  };

  const tweetDirectlyAs = async ({
    tweetId = null,
    tweetContent,
    originalTweetId = null,
    quotedTweetUrl = null,
  }) => {
    if (tweetId) {
      setPostingTweet((prev) => ({ ...prev, [tweetId]: true }));
    }
    setPostSybilNowLoading(true);
    const url = "https://twitter135.p.rapidapi.com/v1/CreateTweet";
    const [username, authToken] = twitterAuth.split("@");
    const [proxyHost, proxyPort, proxyUsername, proxyPassword] =
      proxy.split(":");

    let finalTweetContent = tweetContent;
    if (quotedTweetUrl) {
      finalTweetContent += ` ${quotedTweetUrl}`;
    }

    const options = {
      method: "POST",
      headers: {
        "x-rapidapi-key": rapidapiKey,
        "x-rapidapi-host": "twitter135.p.rapidapi.com",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        auth_token: authToken,
        proxy: {
          host: proxyHost,
          port: proxyPort,
          username: proxyUsername,
          password: proxyPassword,
        },
        text: finalTweetContent,
        ...(originalTweetId && { reply_to_tweet_id: originalTweetId }),
      }),
    };

    try {
      const response = await fetch(url, options);
      const result = await response.json();
      console.log(result);

      if (
        result.data &&
        result.data.create_tweet &&
        result.data.create_tweet.tweet_results &&
        result.data.create_tweet.tweet_results.result
      ) {
        const restId = result.data.create_tweet.tweet_results.result.rest_id;
        const tweetUrl = `https://x.com/user/status/${restId}`;
        setPostedTweetUrls((prev) => ({ ...prev, [tweetId]: tweetUrl }));
        message.success(
          <span>
            Tweet posted successfully! View it{" "}
            <a href={tweetUrl} target="_blank" rel="noopener noreferrer">
              here
            </a>
            .
          </span>
        );
      } else {
        throw new Error("Unexpected response structure");
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to post tweet");
    } finally {
      if (tweetId) {
        setPostingTweet((prev) => ({ ...prev, [tweetId]: false }));
      }
      setPostSybilNowLoading(false);
    }
  };

  const handleSybilNowTweetSubmit = () => {
    tweetDirectlyAs({ tweetContent: sybilTweetContent });
    setSybilTweetContent("");
  };

  const settingsContent = (
    <div style={{ maxWidth: "80vw" }}>
      <label>Campaign</label>
      <Input
        placeholder="Campaign"
        value={campaign}
        onChange={(e) => setCampaign(e.target.value)}
        style={{ marginBottom: 10 }}
      />

      <Divider />
      <label>OpenAI Key</label>
      <Input.Password
        prefix={<KeyOutlined />}
        type="password"
        placeholder="OpenAI API Key"
        value={openaiKey}
        onChange={(e) => setOpenaiKey(e.target.value)}
        style={{ marginBottom: 10 }}
      />
      <label>Rapid API Key</label>
      <Input.Password
        prefix={<KeyOutlined />}
        type="password"
        placeholder="RapidAPI Key"
        value={rapidapiKey}
        onChange={(e) => setRapidapiKey(e.target.value)}
        style={{ marginBottom: 10 }}
      />
      <label>Backend Webhook URL</label>
      <Input
        placeholder="Backend Webhook URL"
        value={backendWebhookUrl}
        onChange={(e) => setBackendWebhookUrl(e.target.value)}
        style={{ marginBottom: 10 }}
      />
      <Divider />

      <Switch
        checked={tagRandomMentionFlag}
        onChange={(checked) => setTagRandomMentionFlag(checked)}
        style={{ marginBottom: 10 }}
      />
      <label>Tag Random Mention</label>
      <br />
      <label>Random User Backend URL</label>
      <Input
        placeholder="Backend Webhook URL User Mentions"
        value={backendWebhookUserMentionsURL}
        onChange={(e) => setBackendWebhookUserMentionsURL(e.target.value)}
        style={{ marginBottom: 10 }}
      />
      <Divider />
      <Switch
        checked={useQuoteTweet}
        onChange={(checked) => setUseQuoteTweet(checked)}
        style={{ marginBottom: 10 }}
      />
      <label>Use Quote Tweet</label>
      <br />
      <label>Pinecone Host URL</label>
      <Input
        placeholder="Pinecone Host URL - eg. https://myindex-358598.svc.aped-535-b74a.pinecone.io"
        value={pineconeUrl}
        onChange={(e) => setPineconeUrl(e.target.value)}
        style={{ marginBottom: 10 }}
      />
      <label>Pinecone Namespace</label>
      <Input
        placeholder="Pinecone Namespace"
        value={pineconeNamespace}
        onChange={(e) => setPineconeNamespace(e.target.value)}
        style={{ marginBottom: 10 }}
      />
      <label>Pinecone API Key</label>
      <Input.Password
        prefix={<KeyOutlined />}
        type="password"
        placeholder="Pinecone API Key"
        value={pineconeKey}
        onChange={(e) => setPineconeKey(e.target.value)}
        style={{ marginBottom: 10 }}
      />
      <label>Pinecone Metadata - Twitter Username</label>
      <Input
        placeholder="Pinecone Twitter Username"
        value={pineconePineconeUsername}
        onChange={(e) => setPineconePineconeUsername(e.target.value)}
        style={{ marginBottom: 10 }}
      />
      <label>Pinecone Tags Csv</label>
      <Input
        placeholder="Pinecone Tags Csv"
        value={tagsCsv}
        onChange={(e) => setTagsCsv(e.target.value)}
        style={{ marginBottom: 10 }}
      />
    </div>
  );

  const sybilContent = (
    <div>
      <label>Proxy</label>
      <Input.Password
        placeholder="ip:port:user:pass"
        value={proxy}
        onChange={(e) => setProxy(e.target.value)}
        style={{ marginBottom: 10 }}
      />
      <label>Twitter Auth</label>
      <Input.Password
        placeholder="username@auth_token_cookie"
        value={twitterAuth}
        onChange={(e) => setTwitterAuth(e.target.value)}
        style={{ marginBottom: 10 }}
      />
      <label>RapidAPI Key</label>
      <Input.Password
        prefix={<KeyOutlined />}
        type="password"
        placeholder="RapidAPI Key"
        value={rapidapiKey}
        onChange={(e) => setRapidapiKey(e.target.value)}
        style={{ marginBottom: 10 }}
      />
      <Switch
        checked={useTweetDirectly ? true : false}
        onChange={(checked) => setUseTweetDirectly(checked)}
        style={{ marginBottom: 10 }}
      />
      <label>Use Sybil Now</label>
      <br />
      <label>Send a Tweet Now</label>
      <Input.TextArea
        rows={4}
        value={sybilTweetContent}
        onChange={(e) => setSybilTweetContent(e.target.value)}
        placeholder="Enter your tweet content here"
        style={{ marginBottom: 10 }}
      />
      <Button
        type="primary"
        onClick={handleSybilNowTweetSubmit}
        loading={postSybilNowLoading}
      >
        Post Tweet as {twitterAuth.split("@")[0]}
      </Button>
    </div>
  );

  console.log("savedToCampaign", savedToCampaign);

  return (
    <div style={{ padding: "20px" }}>
      <p style={{ fontFamily: "sans-serif" }}>https://runsybil.run v0.2</p>
      <Title level={1}>Twitter Campaign</Title>
      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col>
          <Popover content={settingsContent} title="Settings" trigger="click">
            <Button icon={<SettingOutlined />}>Settings</Button>
          </Popover>
        </Col>
        <Col>
          <Popover content={sybilContent} title="Sybil" trigger="click">
            <Button icon={<UserSwitchOutlined />}>Sybil Now</Button>
          </Popover>
        </Col>
      </Row>
      <Row gutter={20} style={{ marginBottom: 20 }}>
        <Col span={4}>
          <Switch
            checked={isFeedMode}
            onChange={setIsFeedMode}
            checkedChildren="Feed"
            unCheckedChildren="List"
          />
        </Col>
        <Col span={11}>
          {isFeedMode ? (
            <Input
              prefix={<TwitterOutlined />}
              placeholder="Enter search term for feed"
              value={feedSearchTerm}
              onChange={(e) => setFeedSearchTerm(e.target.value)}
            />
          ) : (
            <Input
              prefix={<TwitterOutlined />}
              placeholder="Twitter List ID or URL"
              value={listId}
              onChange={(e) => setListId(e.target.value)}
            />
          )}
        </Col>
        <Col span={3}>
          <Button
            loading={loading}
            type="primary"
            onClick={
              isFeedMode ? fetchInitialFeedTweets : fetchInitialListTweets
            }
          >
            {isFeedMode ? "Fetch Feed" : "Fetch List"}
          </Button>
        </Col>

        <Col span={3}>
          <Button onClick={generateAllResponses}>Generate All</Button>
        </Col>
        <Col span={3}>
          <Button loading={saveAllLoading} onClick={saveAllToCampaign}>
            Save All
          </Button>
        </Col>
      </Row>
      <p>{`${tweets.length} Results`}</p>
      <List
        itemLayout="vertical"
        dataSource={tweets}
        renderItem={(item, idx) => {
          if (
            item.content.entryType === "TimelineTimelineItem" &&
            item.content.itemContent.tweet_results.result.__typename === "Tweet"
          ) {
            const tweet = item.content.itemContent.tweet_results.result;

            const tweetId = tweet.rest_id;
            const tweetDate = moment(
              tweet.legacy?.created_at,
              "ddd MMM DD HH:mm:ss ZZ YYYY"
            ).format("MMMM D, YYYY HH:mm");
            return (
              <List.Item>
                <Row gutter={16}>
                  <Col span={12}>
                    <div>{tweet.legacy.full_text}</div>
                    <div style={{ fontSize: "0.8em", color: "#888" }}>
                      {`${
                        tweet.core.user_results.result.legacy.screen_name
                      } - ${formatNumberWithSuffix(tweet.views.count)} Views -
                      ${tweetDate}`}
                    </div>
                    <a
                      href={`https://x.com/user/status/${tweet.rest_id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {`View - #${idx + 1}`}
                    </a>
                  </Col>
                  <Col span={12}>
                    <Input.TextArea
                      rows={4}
                      value={responses[tweetId] || ""}
                      placeholder="Generated response will appear here"
                      style={{ marginBottom: 10 }}
                      onChange={(e) =>
                        setResponses((prev) => ({
                          ...prev,
                          [tweetId]: e.target.value,
                        }))
                      }
                    />
                    <Input
                      placeholder="Quote Tweet URL"
                      value={quoteTweetMetadatas[tweetId]?.url || ""}
                      onChange={(e) =>
                        setQuoteTweetMetadatas((prev) => ({
                          ...prev,
                          [tweetId]: {
                            url: e.target.value,
                            text: "",
                            username: "",
                          },
                        }))
                      }
                      style={{ marginBottom: 10 }}
                    />
                    <Input.TextArea
                      rows={2}
                      value={quoteTweetMetadatas[tweetId]?.text || ""}
                      placeholder="Quoted tweet will appear here"
                      style={{ marginBottom: 10 }}
                    />
                    <Button
                      icon={<LeftOutlined />}
                      onClick={() => iterateResponseOptions(tweetId, -1)}
                      disabled={
                        !matchesState[tweetId] ||
                        matchesState[tweetId].matches.length === 0
                      }
                      style={{ marginRight: 5 }}
                    />
                    <Button
                      icon={<MessageOutlined />}
                      loading={generatingResponses[tweetId]}
                      onClick={() =>
                        generateResponse(tweetId, tweet.legacy.full_text)
                      }
                      style={{ marginRight: 10 }}
                    >
                      Generate
                    </Button>
                    <Button
                      icon={<RightOutlined />}
                      onClick={() => iterateResponseOptions(tweetId, 1)}
                      disabled={
                        !matchesState[tweetId] ||
                        matchesState[tweetId].matches.length === 0
                      }
                      style={{ marginRight: 10 }}
                    />

                    <Button
                      icon={<ClearOutlined />}
                      onClick={() => clearResponse(tweetId)}
                    >
                      Clear
                    </Button>

                    {useTweetDirectly ? (
                      <div>
                        <Button
                          type="primary"
                          icon={<TwitterOutlined />}
                          onClick={() =>
                            tweetDirectlyAs({
                              tweetId,
                              tweetContent: responses[tweetId],
                              originalTweetId: tweet.rest_id,
                              quotedTweetUrl: quoteTweetMetadatas[tweetId]?.url,
                            })
                          }
                          loading={postingTweet[tweetId]}
                        >
                          Tweet Now as {twitterAuth.split("@")[0]}
                        </Button>
                        {postedTweetUrls[tweetId] && (
                          <a
                            href={postedTweetUrls[tweetId]}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ marginLeft: "10px" }}
                          >
                            View
                          </a>
                        )}
                      </div>
                    ) : (
                      <Button
                        type="primary"
                        icon={<SaveOutlined />}
                        loading={savingStates[tweetId]}
                        onClick={() => saveToCampaign(tweetId)}
                        disabled={savedToCampaign[tweetId]}
                      >
                        {savedToCampaign[tweetId]
                          ? `Successfully Saved`
                          : `Save To Campaign`}
                      </Button>
                    )}
                  </Col>
                </Row>
              </List.Item>
            );
          }
          return null;
        }}
      />
      <Button
        loading={loading}
        onClick={isFeedMode ? fetchMoreFeedTweets : fetchMoreListTweets}
        style={{ marginTop: "20px" }}
      >
        Load More <DownOutlined />
      </Button>
      <div
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
          padding: "20px",
        }}
      >
        <Row>
          <Col span={18}>
            <Input.TextArea
              rows={4}
              placeholder="Enter your prompt here"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              style={{ marginBottom: 10 }}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Spearfisher;
